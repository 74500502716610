export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは2月より%d点上がりました。年度末の慌ただしい時期に素晴らしいです！<br>\n環境や関わる人が変化することが多いこの時期、規則正しい生活や良い生活習慣を心がけて新生活を元気に乗り切りましょう。<a href=\"https://go.andwell.jp/library/f062c3a5-0687-11ee-a8af-06528b823331\">こちらの動画</a>では、好印象をつくる コミュニケーション スキルをご紹介！\n\n",
      "down": "生活習慣スコアは2月より%d点下がりました。分類別評価でどの項目に変化があったのか見てみましょう。<br>\n環境や関わる人が変化することが多いこの時期、規則正しい生活や良い生活習慣を心がけて新生活を元気に乗り切りましょう。<a href=\"https://go.andwell.jp/library/f062c3a5-0687-11ee-a8af-06528b823331\">こちらの動画</a>では、好印象をつくる コミュニケーション スキルをご紹介！\n\n",
      "equal100": "生活習慣スコアは2月に引き続き%満点%です。年度末の慌ただしい時期に大変素晴らしいです！<br>\n環境や関わる人が変化することが多いこの時期、規則正しい生活や良い生活習慣を心がけて新生活を元気に乗り切りましょう。<a href=\"https://go.andwell.jp/library/f062c3a5-0687-11ee-a8af-06528b823331\">こちらの動画</a>では、好印象をつくる コミュニケーション スキルをご紹介！\n\n",
      "equal": "生活習慣スコアは2月と同じ点数です。<br>\n環境や関わる人が変化することが多いこの時期、規則正しい生活や良い生活習慣を心がけて新生活を元気に乗り切りましょう。<a href=\"https://go.andwell.jp/library/f062c3a5-0687-11ee-a8af-06528b823331\">こちらの動画</a>では、好印象をつくる コミュニケーション スキルをご紹介！\n\n"
    },
    "steps": {
      "up": "歩数スコアは2月より%d点上がりました。日々の歩数が増えています。<br>\n<b>目線は前方、腕を後ろへ引く</b>など正しいフォームを意識するとより効果的です！\n\n",
      "down": "歩数スコアは2月より%d点下がりました<br>\n合計時間が同じであれば、こまめに歩いた時と長時間歩いた時の効果には変わりないと言われています。今より10分多く歩くことを意識してみましょう。\n\n",
      "equal": "歩数スコアは2月と同じ点数です。<br>\n合計時間が同じであれば、こまめに歩いた時と長時間歩いた時の効果には変わりないと言われています。今より10分多く歩くことを意識してみましょう。\n\n"
    },
    "exercise": {
      "up": "運動のスコアは2月より%d点上がりました。この調子です！<br>\n階段を積極的に利用したり、早歩きしたりなど、ちょっとした工夫や心がけで日常生活の中で活動量を増やすことができますよ。\n\n",
      "down": "運動のスコアは2月より%d点下がりました。<br>\n階段を積極的に利用したり、早歩きしたりなど、ちょっとした工夫や心がけで日常生活の中で活動量を増やすことができますよ。\n\n",
      "equal": "運動のスコアは2月と同じ点数です。<br>\n階段を積極的に利用したり、早歩きしたりなど、ちょっとした工夫や心がけで日常生活の中で活動量を増やすことができますよ。\n\n"
    },
    "meal": {
      "up": "食事のスコアは2月より%d点上がりました。<br>\n食生活と自律神経は、密接に関わっていると言われています。<a href=\"https://go.andwell.jp/library/49231ac4-d563-11ee-9661-0a74c02d2ee3\">こちらのコラム</a>では、食生活で気をつけることをお伝え！\n\n",
      "down": "食事のスコアは2月より%d点下がりました。<br>\n食生活と自律神経は、密接に関わっていると言われています。<a href=\"https://go.andwell.jp/library/49231ac4-d563-11ee-9661-0a74c02d2ee3\">こちらのコラム</a>では、食生活で気をつけることをお伝え！\n\n",
      "equal": "食事のスコアは2月と同じ点数です。<br>\n食生活と自律神経は、密接に関わっていると言われています。<a href=\"https://go.andwell.jp/library/49231ac4-d563-11ee-9661-0a74c02d2ee3\">こちらのコラム</a>では、食生活で気をつけることをお伝え！\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは2月より%d点上がりました。飲み過ぎた日が少なくなっていますね。<br>\n「疲れやだるさを感じにくくなった」や「よく眠れるようになった」など、何か変化はありますか？\n\n",
      "down": "飲酒のスコアは2月より%d点下がりました。<br>\nお酒を飲む時は、一緒に食事をすることを心がけましょう。空腹状態で飲んでしまうと、アルコールの吸収が早まり、悪酔いする可能性があります。\n\n",
      "equal": "飲酒のスコアは2月と同じ点数です。<br>\nお酒を飲む時は、一緒に食事をすることを心がけましょう。空腹状態で飲んでしまうと、アルコールの吸収が早まり、悪酔いする可能性があります。\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは2月より%d点上がりました。<br>\n春は1年の中で特に寒暖差が激しく、睡眠の質が下がりやすい時期です。朝起きたらすぐに太陽の光を浴びて、体内時計をリセット！\n\n",
      "down": "睡眠のスコアは2月より%d点下がりました。<br>\n春は1年の中で特に寒暖差が激しく、睡眠の質が下がりやすい時期です。朝起きたらすぐに太陽の光を浴びて、体内時計をリセット！\n\n",
      "equal": "睡眠のスコアは2月と同じ点数です。<br>\n春は1年の中で特に寒暖差が激しく、睡眠の質が下がりやすい時期です。朝起きたらすぐに太陽の光を浴びて、体内時計をリセット！\n\n"
    },
    "stress": {
      "up": "ストレスのスコアは2月より%d点上がりました。気分が良いと感じる日が増えていますね。<br>\nストレスが軽減されると、免疫力アップ効果が期待できます。\n\n",
      "down": "ストレスのスコアは2月より%d点下がりました。気分が悪いと感じる日が多くなっていますね。<br>\n好きな音楽を聴く、深呼吸をするなど、あなたにあったリラックス方法を探してみてください♪\n\n",
      "equal": "ストレスのスコアは2月と同じ点数です。<br>\n好きな音楽を聴く、深呼吸をするなど、あなたにあったリラックス方法を探してみてください♪\n\n"
    }
  },
  "en": {
    "score": {
      "up": "Your lifestyle score has gone up by %d pts since February. This is an outstanding achievement for the hectic end of the fiscal year!<br>\nDuring this time of year, when the environment and the people you interact with are often changing, let's tackle our new lives with energy by maintaining a regular lifestyle and good habits. In <a href=\"https://go.andwell.jp/library/f062c3a5-0687-11ee-a8af-06528b823331\">this video</a>, we will introduce communication skills to help you make a good impression!\n\n",
      "down": "Your lifestyle score has gone down by %d pts since February. Use the categorized ratings to see what items have changed.<br>\nDuring this time of year, when the environment and the people you interact with are often changing, let's tackle our new lives with energy by maintaining a regular lifestyle and good habits. In <a href=\"https://go.andwell.jp/library/f062c3a5-0687-11ee-a8af-06528b823331\">this video</a>, we will introduce communication skills to help you make a good impression!\n\n",
      "equal100": "Your lifestyle score is perfect, as it was in February.This is an outstanding achievement for the hectic end of the fiscal year!<br>\nDuring this time of year, when the environment and the people you interact with are often changing, let's tackle our new lives with energy by maintaining a regular lifestyle and good habits. In <a href=\"https://go.andwell.jp/library/f062c3a5-0687-11ee-a8af-06528b823331\">this video</a>, we will introduce communication skills to help you make a good impression!\n\n",
      "equal": "Your lifestyle score is the same as February.<br>\nDuring this time of year, when the environment and the people you interact with are often changing, let's tackle our new lives with energy by maintaining a regular lifestyle and good habits. In <a href=\"https://go.andwell.jp/library/f062c3a5-0687-11ee-a8af-06528b823331\">this video</a>, we will introduce communication skills to help you make a good impression!\n\n"
    },
    "steps": {
      "up": "Your steps score has gone up %d pts since February.The number of daily steps is increasing.<br>\n<b>Being aware of proper form, such as keeping your eyes forward</a> and pulling your arms back, can make your walking more effective!\n\n",
      "down": "Your steps score has gone down %d pts since February. <br>\nIt is said that if the total time remains the same, there is no difference in the effectiveness of walking more frequently or for longer periods of time. Consider adding 10 minutes to your current walking routine.\n\n",
      "equal": "Your steps score is the same as February. <br>\nIt is said that if the total time remains the same, there is no difference in the effectiveness of walking more frequently or for longer periods of time. Consider adding 10 minutes to your current walking routine.\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d pts since February. Keep it up!<br>\nYou can increase your activity level in your daily life with just a bit of ingenuity and effort, like actively taking the stairs or walking fast.\n\n",
      "down": "Your exercise score has gone down %d pts since February.<br>\nYou can increase your activity level in your daily life with just a bit of ingenuity and effort, like actively taking the stairs or walking fast.\n\n",
      "equal": "Your exercise score is the same as February.<br>\nYou can increase your activity level in your daily life with just a bit of ingenuity and effort, like actively taking the stairs or walking fast.\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d pts since February.<br>\nIt is said that dietary habits and the autonomic nervous system are closely related. In <a href=\"https://go.andwell.jp/library/49231ac4-d563-11ee-9661-0a74c02d2ee3\">this column</a>, we will discuss what you should pay attention to in your diet!\n\n",
      "down": "Your food score has gone down %d pts since February.<br>\nIt is said that dietary habits and the autonomic nervous system are closely related. In <a href=\"https://go.andwell.jp/library/49231ac4-d563-11ee-9661-0a74c02d2ee3\">this column</a>, we will discuss what you should pay attention to in your diet!\n\n",
      "equal": "Your food score is the same as February.<br>\nIt is said that dietary habits and the autonomic nervous system are closely related. In <a href=\"https://go.andwell.jp/library/49231ac4-d563-11ee-9661-0a74c02d2ee3\">this column</a>, we will discuss what you should pay attention to in your diet!\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d pts since February.Your excessive drinking days are decreasing.<br>\nDo you notice any changes, such as \"feel less tired and sluggish\" or \"sleep better\"?\n\n",
      "down": "Your alcohol score has gone down %d pts since February.<br>\nWhen drinking alcohol, be sure to eat with it. Drinking on an empty stomach can accelerate alcohol absorption and may lead to a worse hangover.\n\n",
      "equal": "Your alcohol score is the same as February.<br>\nWhen drinking alcohol, be sure to eat with it. Drinking on an empty stomach can accelerate alcohol absorption and may lead to a worse hangover.\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d pts since February. <br>\nTemperatures vary heavily in spring, so your sleep quality can easily deteriorate in this part of the year.As soon as you wake up in the morning, get into the sunlight and reset your internal clock!\n\n",
      "down": "Your sleep score has gone down %d pts since February. <br>\nTemperatures vary heavily in spring, so your sleep quality can easily deteriorate in this part of the year.As soon as you wake up in the morning, get into the sunlight and reset your internal clock!\n\n",
      "equal": "Your sleep score is the same as February. <br>\nTemperatures vary heavily in spring, so your sleep quality can easily deteriorate in this part of the year.As soon as you wake up in the morning, get into the sunlight and reset your internal clock!\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d pts since February. Days you feel good are increasing.<br>\nReduced stress can have a positive effect on your immune system.\n\n",
      "down": "Your stress score has gone down %d pts since February. Days you feel bad are increasing.<br>\nFind a way to relax that works for you, such as listening to your favorite music or taking deep breaths.\n\n",
      "equal": "Your stress score is the same as February. <br>\nFind a way to relax that works for you, such as listening to your favorite music or taking deep breaths.\n\n"
    }
  }
}